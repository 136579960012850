/* Import reset.css */
/* 
The reset.css is disabled because we are using Bootstrap framework
@import url("reset.css");
*/

/* Import Google Font (Inter & Roboto Mono) */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');


/* Variables */
:root {
	--h7-color-main-layout			: #FFFFFF;
	--h7-color-page-background		: #EFF3F6;
	--h7-color-main-border			: #DEE4E9;
	--h7-color-panel				: #F4F6F7;
	--h7-color-main-text			: #182039;
	--h7-color-muted-text			: #999FAB;
	--h7-color-light-text			: #9CA1AB;
	--h7-color-secondary-hover		: #CAD3DA;
	--h7-color-primary				: #3E64FF;
	--h7-color-danger				: #D84C10;
	--h7-color-warning				: #FFBA08;

	--h7-color-primary-30			: #C4CEF9;
	--h7-color-danger-30			: #F9E4DB;

	--h7-color-primary-darken 		: #244BEB;
	--h7-color-danger-darken 		: #C5440D;

	--h7-font-weight-regular		: 400;
	--h7-font-weight-medium			: 500;
	--h7-font-weight-semi-bold		: 600;
	--h7-font-weight-bold			: 700;

	--h7-font-size-heading			: 30px;
	--h7-font-size-xxlarge			: 24px;
	--h7-font-size-xlarge			: 20px;
	--h7-font-size-large			: 18px;
	--h7-font-size-medium			: 16px;
	--h7-font-size-main				: 14px;
	--h7-font-size-small			: 12px;
	--h7-font-size-xsmall			: 10px;

	--h7-layout-sidebar-size		: 310px;
	--h7-layout-sidebar-logo-size	: 120px;
	--h7-layout-header-size			: 60px;
	--h7-layout-chat-input-size		: 60px;
	--h7-layout-footer-size			: 50px;
	--h7-layout-main-radius			: 20px;
	

	--h7-layout-main-space			: 30px;
	--h7-layout-main-medium-space	: 20px;
	--h7-layout-main-small-space	: 15px;
	--h7-layout-main-xsmall-space	: 10px;

	--h7-layout-z-index-sidebar		: 31;
	--h7-layout-z-index-overlay		: 30;
	--h7-layout-z-index-header		: 29;
	--h7-layout-z-index-chat-input	: 28;

	--h7-btn-height					: 40px;
	--h7-btn-sm-height				: 30px;
	--h7-btn-lg-height				: 60px;

	--h7-input-height				: 50px;
}




/* 
=================================
BOOTSTRAP RE-STYLING
=================================
*/
/* Panel styles */
.panel {
	border-radius: var(--h7-layout-main-radius);
	padding: var(--h7-layout-main-medium-space);
}

/* Card styles */
.card {
	border-color: var(--h7-color-main-border);
}

/* Button styles */
.btn {
	font-size: var(--h7-font-size-main);
	padding-left: var(--h7-layout-main-medium-space);
	padding-right: var(--h7-layout-main-medium-space);
	height: var(--h7-btn-height);
	border-radius: var(--h7-layout-main-radius);
	font-weight: var(--h7-font-weight-medium);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
a.btn {
	padding-top: 8px;
}
a.btn-lg {
	padding-top: 18px;
}
.btn.btn-primary {
	background-color: var(--h7-color-primary);
	border-color: var(--h7-color-primary);
}
.btn.btn-primary:hover,
.btn.btn-primary:active {
	background-color: var(--h7-color-primary-darken);
	border-color: var(--h7-color-primary-darken);
}
.btn.btn-danger {
	background-color: var(--h7-color-danger);
	border-color: var(--h7-color-danger);
}
.btn.btn-danger:hover,
.btn.btn-danger:active {
	background-color: var(--h7-color-danger-darken);
	border-color: var(--h7-color-danger-darken);
}
.btn.btn-secondary {
	background-color: var(--h7-color-main-border);
	border-color: var(--h7-color-main-border);
	color: var(--h7-color-main-text);
}
.btn.btn-secondary:hover,
.btn.btn-secondary:active {
	background-color: var(--h7-color-secondary-hover);
	border-color: var(--h7-color-secondary-hover);
	color: var(--h7-color-main-text);
}

/* Form control styles */
.form-control,
.form-select {
	background-color: var(--h7-color-panel);
	border-color: var(--h7-color-main-border);
	font-size: var(--h7-font-size-main);
	height: var(--h7-input-height);
	border-radius: calc(var(--h7-input-height) / 2);
	padding-left: 25px;
	padding-right: 25px;
}
.form-control::-moz-placeholder {
	color: var(--h7-color-main-border);
}
.form-control::placeholder {
	color: var(--h7-color-main-border);
}
.form-control.is-invalid {
	background-image: none;
	border-color: var(--h7-color-danger);
	background-color: var(--h7-color-danger-30);
}
.form-control.is-invalid::-moz-placeholder {
	color: var(--h7-color-muted-text);
}
.form-control.is-invalid::placeholder {
	color: var(--h7-color-muted-text);
}
.form-control:disabled {
	background-color: var(--h7-color-main-border);
	color: var(--h7-color-light-text);
}
textarea.form-control {
	padding-top: var(--h7-layout-main-small-space);
	min-height: 110px;
	resize: none;
	overflow: auto;
}
.form-label {
	font-weight: var(--h7-font-weight-medium);
}
.form-text {
	color: var(--h7-color-muted-text);
	font-size: var(--h7-font-size-small);
}

/* Tooltip */
.tooltip-inner {
	--bs-tooltip-border-radius: var(--h7-layout-main-radius);
	--bs-tooltip-padding-x: 15px;
	font-size: var(--h7-font-size-small);
}

.tooltip-icon-circle {
	display: inline-block;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: var(--h7-color-main-border);
	color: var(--h7-color-main-text);
	text-align: center;
	line-height: 20px;
	font-weight: var(--h7-font-weight-bold);
	font-size: 14px;
	cursor: pointer;
	margin-left: 8px;
  }
  
  .tooltip-icon-circle:hover {
	background-color: var(--h7-color-primary);
	color: white;
  }
  
  

 /* Modals */
.modal-content {
	color: var(--h7-color-main-text);
	border-color: var(--h7-color-main-border);
	border-radius: var(--h7-layout-main-radius);
}
.modal-body {
	padding: 50px;
}
.modal-content h3 {
	font-size: var(--h7-font-size-xlarge);
}
.modal-content .panel-icon {
	background-color: var(--h7-color-primary);
	width: 80px;
	height: 80px;
	box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.05);
}
.modal-content .panel-icon img {
	width: 36px;
}


/* Dropdown menu */
.dropdown-menu {
	display: none; /* Hide by default */
	position: absolute;
	right: 0;
	top: 100%;
	margin: 0;
	transform: translate3d(0, 0, 0);
	z-index: 1000;
	border-color: var(--h7-color-main-border);
	border-radius: 10px;
}
  
.dropdown-menu.show {
	display: block; /* Show when dropdownVisible is true */
}
.dropdown-menu .dropdown-item {
	color: var(--h7-color-main-text);
	font-size: var(--h7-font-size-main);
	user-select: none;
}

.dropdown-item:hover {
	background-color: #f8f9fa; /* Change background on hover */
	color: #212529; /* Change text color on hover */
  }

/* Layout */
body {
	font-family: "Inter", sans-serif;
	background-color: var(--h7-color-page-background);
	color: var(--h7-color-main-text);
	font-size: var(--h7-font-size-main);
	font-weight: var(--h7-font-weight-regular);
	font-optical-sizing: auto;
	font-style: normal;
	font-variation-settings:
		"slnt" 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: var(--h7-font-weight-semi-bold);
}




/* 
=================================
GLOBAL COMPONENTS
=================================
*/
/* Panel Styling */
.panel-icon {
	border-radius: var(--h7-layout-main-radius);
	margin: 0 auto;
	display: flex;
	justify-content: center;
	align-items: center;
}
.panel-text {
	padding: var(--h7-layout-main-medium-space) var(--h7-layout-main-space);
	background-color: var(--h7-color-light-text);
	border-radius: var(--h7-layout-main-radius);
	min-height: 100px;
}
.panel-text.text-white {
	color: var(--h7-color-main-layout);
}
.panel-text.primary {
	background-color: var(--h7-color-primary);
}
.panel-text.text-mono {
	font-family: "Roboto Mono", monospace;
	font-optical-sizing: auto;
	font-weight: var(--h7-font-weight-regular);
	font-style: normal;
}

/* Checkbox styling */
.btn-check+.btn {
	font-weight: var(--h7-font-weight-regular);
	padding-left: var(--h7-layout-main-medium-space);
	padding-top: 8px;
	text-align: left;
	white-space: normal;
	overflow: visible;
	text-overflow: none;
	min-height: 40px;
	height: auto !important;
}
.btn-check+.btn-outline-secondary {
	border-color: var(--h7-color-main-border);
	color: var(--h7-color-main-text);
}
.btn-check:checked + .btn-outline-secondary {
	background-color: var(--h7-color-primary);
	color: var(--h7-color-main-layout);
}  
.btn-check:hover+.btn-outline-secondary {
	border-color: var(--h7-color-primary);
	color: var(--h7-color-primary);
	background-color: rgba(62, 100, 255, .15);
}
.btn-check:checked+.btn-outline-secondary:hover,
.btn-check:checked+.btn-outline-secondary {
	border-color: var(--h7-color-primary);
	background-color: var(--h7-color-primary);
	color: var(--h7-color-main-layout);
}



/* 
=================================
PAGE LAYOUT
=================================
*/
/* Landing Pages :
	- Landing
	- Join Room Pages
	- Create Room Pages
	- Invite Pages
*/
.landing-wrap {
	min-height: calc(100vh - var(--h7-layout-footer-size));
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 0;
}
.landing-container {
	width: 500px;
	margin-bottom: calc(var(--h7-layout-main-space) * 2);
}
.landing-logo {
	height: 180px;
}

/* Main Inner Pages */
.wrapper {
	position: relative;
}
.sidebar {
	background-color: var(--h7-color-main-layout);
	padding: 0 var(--h7-layout-main-space) 0;
}
.sidebar.fixed {
	width: var(--h7-layout-sidebar-size);
	border-right: 1px solid var(--h7-color-main-border);
	z-index: var(--h7-layout-z-index-sidebar);
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	overflow-y: auto;
}
.sidebar-logo,
.header-logo {
	padding: var(--h7-layout-main-medium-space);
	height: var(--h7-layout-header-size);
	/* margin-bottom: var(--h7-layout-main-medium-space); */
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
.sidebar-logo {
	height: auto;
}
.sidebar-logo img,
.header-logo img {
	width: 174px;
	display: block;
}
.main {
	margin-left: var(--h7-layout-sidebar-size);
	width: calc(100% - var(--h7-layout-sidebar-size));
}

/* Header */
header {
	height: var(--h7-layout-header-size);
	left: var(--h7-layout-sidebar-size);
	background-color: var(--h7-color-main-layout);
	z-index: var(--h7-layout-z-index-header);
	border-bottom: 1px solid var(--h7-color-main-border);
	padding: 0 var(--h7-layout-main-space);
	position: fixed;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
header .btn-menu,
header .button-group .btn-primary, 
header .button-group .btn-secondary {
	position: relative;
	padding-left: 50px;
}
header .button-group .btn-primary {
	margin-right: 5px;
}
header .btn-menu::before,
header .button-group .btn-primary::before,
header .button-group .btn-secondary::before {
	left: var(--h7-layout-main-medium-space);
	width: 20px;
	height: 20px;
	content: "";
	position: absolute;
	top: 50%;
	margin-top: -10px;
	background-repeat: no-repeat;
	background-position: center;
}
header .button-group .btn-primary::before {
	background-image: url(../img/16-Invite-Users-Icon-White.svg)
}
header .button-group .btn-secondary.show::before {
	background-image: url(../img/16x16-Visible-Message-Icon.svg)
}
header .button-group .btn-secondary.hide::before {
	background-image: url(../img/16x16-Hidden-Message-Icon.svg)
}
header .btn-menu::before {
	background-image: url(../img/16-Menu-Icon-Dark.svg)
}


/* 
=================================
TIMER FEATURE
=================================
*/
.timer-inputs {
	position: relative;
	display: flex;
	align-items: center;
}
.timer-input-container {
	width: 100px;
	display: flex;
	align-items: center;
	background-color: var(--h7-color-panel);
	/* Match background with form style */
	border-radius: var(--h7-layout-main-radius);
	/* Rounded corners */
	padding: 0 10px;
	/* Padding for spacing */
	border: 1px solid var(--h7-color-main-border);
	/* Border to match form style */
	height: 40px;
	margin-right: var(--h7-layout-main-xsmall-space);
}

@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

.timer-input {
	width: 5ch;
	/* Wider to fit the time comfortably */
	text-align: center;
	border: none;
	/* Remove default border */
	background-color: transparent;
	/* Transparent background */
	outline: none;
	/* Remove outline */
	color: var(--h7-color-main-text);
	/* Text color */
	font-size: var(--h7-font-size-main)+2;
	/* Match the form font size */
	padding: 10px;
	/* Padding inside the input for consistency */
	font-family: 'Share Tech Mono', monospace;
}

.timer-input:disabled {
	color: var(--h7-color-light-text);
	/* Gray out text when disabled */
}

.timer-input::placeholder {
	color: var(--h7-color-light-text);
	/* Placeholder text color */
}

/* Hide the spinner for number inputs */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

@media only screen and (max-width: 1024px) {
	.timer-inputs {
		position: absolute;
		right: var(--h7-layout-main-space);
		top: 50%;
		margin-top: -20px;
	}
	.timer-inputs .btn-primary,
	.timer-inputs .btn-danger { 
		width: 40px;
		padding: var(--h7-layout-main-xsmall-space);
		border-radius: 10px;
		font-size: 0;
		position: relative;
	}
	.timer-inputs .btn-primary::before,
	.timer-inputs .btn-danger::before {
		margin-left: -11px;
		left: var(--h7-layout-main-medium-space);
		width: 20px;
		height: 20px;
		content: "";
		position: absolute;
		top: 50%;
		margin-top: -10px;
		background-repeat: no-repeat;
		background-position: center;
	}
	.timer-inputs .btn-primary::before {
		background-image: url(../img/16-Play-Icon.svg);
	}
	.timer-inputs .btn-danger::before {
		background-image: url(../img/16-Pause-Icon.svg);
	}
}

@media only screen and (max-width: 640px) {
	.timer-inputs {
		right: var(--h7-layout-main-small-space);
	}
}


/* Main Content */
.main-content {
	margin-top: var(--h7-layout-header-size);
	/* margin-bottom: calc(var(--h7-layout-chat-input-size) + (var(--h7-layout-main-space) * 1.5)); */
	padding: var(--h7-layout-main-space);
	transition: height 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
}
.main-content.empty {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 0;
}

/* Main Content Empty */
.empty-state img {
	margin: 0 auto var(--h7-layout-main-space);
	width: 96px;
	display: block;
}
.empty-state h2 {
	font-weight: var(--h7-font-weight-regular);
}
.empty-state p {
	color: var(--h7-color-muted-text);
}

/* Footer (Landing Pages Only) */
footer {
	height: var(--h7-layout-footer-size);
	font-size: var(--h7-font-size-small);
	color: var(--h7-color-muted-text);
	text-align: center;
	font-size: 12px;
	display: block;
}




/* 
=================================
LANDING STYLES
=================================
*/
.landing-container .panel-icon {
	width: 80px;
	height: 80px;
	background-color: var(--h7-color-primary);
	box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.05);
}
.landing-container .panel {
	padding: 50px;
}
.landing-container .btn-lg {
	height: var(--h7-btn-lg-height);
	border-radius: calc(var(--h7-btn-lg-height) / 2);
}
.landing-container h3 {
	font-size: var(--h7-font-size-xlarge);
}

/* Subscription type */
.sub-type {
	margin-top: 0;
	font-weight: var(--h7-font-weight-bold);
	padding: 0; 
	line-height: 1.2; 
  }

/* Input file */
.input-file {
	background-color: var(--h7-color-main-border);
	display: block;
	position: relative;
	border-radius: 25px;
	padding-right: 50px;
}
.input-file::after {
	right: var(--h7-layout-main-medium-space);
	top: 50%;
	width: 20px;
	height: 20px;
	content: "";
	position: absolute;
	margin-top: -10px;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url(../img/16-File-Upload-Icon-Gray.svg);
}
.input-file input[type="file"] {
	color: var(--h7-color-muted-text);
	height: var(--h7-input-height);
	line-height: 30px;
	cursor: pointer;
	width: 100%;
	display: block;
}
.input-file input[type="file"]::file-selector-button {
	background-color: var(--h7-color-panel);
	margin: 5px var(--h7-layout-main-small-space) 5px 5px;
	height: 40px;
	border-radius: 20px;
	padding-left: 25px;
	padding-right: 25px;
	border-width: 0;
}

/* Back Button with arrow left */
.back-arrow {
	width: 16px;
	height: 10px;
	content: "";
	margin-right: 5px;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url(../img/12-Arrow-left-icon.svg);
	cursor: pointer;
}

/* Form Component */
form .required {
	float: right;
	font-style: italic;
}
.or-divider {
	color: var(--h7-color-light-text);
	font-weight: var(--h7-font-weight-semi-bold);
	margin: var(--h7-layout-main-space) 0;
	text-align: center;
	width: 100%;
	height: 30px;
	line-height: 30px;
	position: relative;
}
.or-divider:before {
	background-color: var(--h7-color-main-border);
	height: 1px;
	width: 40%;
	left: 0;
	top: 50%;
	position: absolute;
	content: "";
}
.or-divider:after {
	background-color: var(--h7-color-main-border);
	height: 1px;
	width: 40%;
	right: 0;
	top: 50%;
	position: absolute;
	content: "";
}
.copy-url {
	position: relative;
}
.copy-url .form-control {
	color: var(--h7-color-muted-text);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 60px;
}
.copy-url .copy-url-icon {
	width: var(--h7-input-height);
	height: var(--h7-input-height);
	position: absolute;
	z-index: 5;
	right: 0;
	top: 0;
	content: "";
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url(../img/16-Copy-Icon-Gray.svg)
}

.copy-url .copy-url-icon.checkmark-icon {
	background-image: url(../img/icons8-checkmark.svg);
  }

.copy-url .copy-url-icon.disabled {
	pointer-events: none;
}

.qrCodeOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
  }
  
.qrCodeOverlay canvas {
width: 80%;
max-width: 400px;
height: auto;
}



/* 
=================================
SIDEBAR STYLES
=================================
*/
.sidebar .sidebar-panel {
	background-color: var(--h7-color-panel);
	margin-bottom: var(--h7-layout-main-medium-space);
}

/* Sidebar Panels */
.sidebar-panel h3 {
	font-size: var(--h7-font-size-large);
	font-weight: var(--h7-font-weight-semi-bold);
	margin-bottom: 5px;
}
.sidebar-panel p {
	color: var(--h7-color-muted-text);
	font-size: var(--h7-font-size-small);
}
.sidebar-panel .panel-icon {
	width: 50px;
	height: 50px;
	border: 1px solid transparent;
}
.sidebar-panel .panel-icon img {
	width: 24px;
}

/* Chatroom Name Panel */
.chatroom-name .panel-icon {
	background-color: var(--h7-color-primary);
	border-color: var(--h7-color-primary);
}
.chatroom-name .created-on {
	font-size: var(--h7-font-size-xsmall);
	padding: 8px var(--h7-layout-main-xsmall-space);
	background-color: var(--h7-color-warning);
	border-radius: var(--h7-layout-main-radius) var(--h7-layout-main-radius) 0 0;
	display: block;
	position: relative;
	bottom: -20px;
}

/* Regular Message Status Panel */
.reg-messages-status .panel-icon {
	background-color: var(--h7-color-main-layout);
	border-color: var(--h7-color-main-border);
}

/* Participant Panel */
.participants{
	padding: 0;
}
.participants .panel-body {
	padding: var(--h7-layout-main-medium-space);
}
.participants .panel-body.no-scroll {
	padding-bottom: var(--h7-layout-main-medium-space);
	padding-top: 0;
}
.participants h3 {
	font-size: var(--h7-font-size-main);
	padding: var(--h7-layout-main-medium-space) var(--h7-layout-main-small-space);
	height: 60px;
	margin: 0;
}
.participants h3.has-shadow {
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.07);
}
.participants h3 span {
	font-weight: var(--h7-font-weight-regular);
	color: var(--h7-color-muted-text);
}
.participants .user-list-heading {
	margin-bottom: 0;
	line-height: 20px;
	position: relative;
}
.participants .user-list-heading span {
	background-color: var(--h7-color-panel);
	padding-right: var(--h7-layout-main-xsmall-space);
	position: relative;
	z-index: 9;
}
.participants .user-list-heading::before {
	background-color: var(--h7-color-main-border);
	position: absolute;
	content: "";
	height: 1px;
	left: 0;
	right: 0;
	top: 49%;
	z-index: 1;
}
p.user-list-heading .badge {
	background-color: var(--h7-color-main-border);
	font-size: var(--h7-font-size-small);
	color: var(--h7-color-light-text);
	font-weight: var(--h7-font-weight-semi-bold);
	float: right;
	padding: 5px 8px;
	border-radius: 4px;
}
p.user-list-heading .badge::before {
	background-color: var(--h7-color-panel);
	position: absolute;
	content: "";
	height: 10px;
	width: 10px;
	left: -10px;
	top: 50%;
	margin-top: -5px;
	z-index: 1;
}
.participants .list-group {
	border-width: 0;
}
.participants .list-group-item {
	background-color: transparent;
	border-width: 0;
	padding-left: 25px;
	padding-top: 13px;
	padding-bottom: 13px;
}
.participants .list-group-item::before {
	position: absolute;
	width: 12px;
	height: 14px;
	content: "";
	background-repeat: no-repeat;
	background-position: center;
	left: 0;
	top: 50%;
	margin-top: -7px;
}
.moderator-list .list-group-item::before {
	background-image: url('../img/12-Avatar-Moderator-Icon.svg');
}
.regular-list .list-group-item::before {
	background-image: url('../img/12-Avatar-Regular-Icon.svg');
}

.sidebar-inner > .d-grid {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	padding: calc(var(--h7-layout-main-medium-space)-5px);
	width: calc(var(--h7-layout-sidebar-size) - 1px);
	background-color: var(--h7-color-main-layout);
}

.sidebar-btn-group .btn::before {
    left: 10px; /* Adjust the position as needed */
    width: 20px;
    height: 20px;
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-repeat: no-repeat;
    background-position: center;
}

.sidebar-btn-group .btn-secondary.invite::before {
    background-image: url(../img/16-Invite-Users-Icon-Gray.svg);
}

.sidebar-btn-group .btn-secondary.show::before {
    background-image: url(../img/16x16-Visible-Message-Icon.svg);
}

.sidebar-btn-group .btn-secondary.hide::before {
    background-image: url(../img/16x16-Hidden-Message-Icon.svg);
}

.sidebar-btn-group .btn {
    padding-left: 40px; /* Space for icon */
    margin-bottom: 10px; /* Padding between buttons */
    position: relative; /* For ::before positioning */
}


/* 
=================================
CHAT INPUTS STYLES
=================================
*/
.chat-input.fixed {
	left: var(--h7-layout-sidebar-size);
	z-index: var(--h7-layout-z-index-chat-input);
	bottom: 0;
	right: 0;
	position: fixed;
}
.chat-input-body {
	background-color: var(--h7-color-main-layout);
	border-top: 1px solid var(--h7-color-main-border);
	padding: var(--h7-layout-main-space);
	position: relative;
	z-index: 19;
}
.chat-input .main-input,
.chat-input .question-input {
	position: relative;
}

/* Chat Ii=nput - Main Input
	- Form control (Textarea)
	- Send Button
	- Question Icons
	- Plus Icon (Mobile)
 */
.chat-input .main-input .form-control {
	min-height: var(--h7-layout-chat-input-size);
	font-size: var(--h7-font-size-main);
	padding-left: var(--h7-layout-main-space);
	padding-top: var(--h7-layout-main-medium-space);
	padding-right: 100px;
	line-height: 16px;
	border-radius: 30px;
}
.chat-input-moderator .main-input .form-control {
	padding-right: 250px;
}
.chat-input .main-input .btn {
	right: var(--h7-layout-main-xsmall-space);
	position: absolute;
	top: 50%;
	margin-top: -20px;
	z-index: 9;
}
.chat-input .main-input .questions-icons {
	position: absolute;
	right: 100px;
	top: 50%;
	margin-top: -20px;
	z-index: 9;
	display: flex;
	justify-content: flex-start;
}

/* Chat Input - Answer Input Group
	- Multiple choice answers
	- Slider question (Slider ranges)
*/
.chat-input .answer-input-group {
	margin-bottom: var(--h7-layout-main-xsmall-space);
	position: relative;
}
.chat-input .answer-input-group .icon-action {
	right: var(--h7-layout-main-medium-space);
	position: absolute;
	width: 30px;
	height: 30px;
	content: "";
	top: 50%;
	margin-top: -15px;
	z-index: 4;
	display: inline-block;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url(../img/16-Trash-Icon-Red.svg)
}
.chat-input .input-answer .form-control {
	padding-left: var(--h7-layout-main-medium-space);
	padding-right: var(--h7-layout-main-medium-space);
	height: 40px;
}
.chat-input .answer-input-group .form-control {
	padding-right: 40px;
}
.input-answer .btn .val-range {
	width: 40px;
	margin: 0 5px;
	text-align: center;
	font-weight: var(--h7-font-weight-semi-bold);
	height: 20px;
	border-color: transparent;
	border-bottom: 1px dashed var(--h7-color-primary);
}

/* Question Icons */
.questions-icons .icon {
	width: 40px;
	height: 40px;
	border: 1px solid transparent;
	content: "";
	border-radius: 20px;
	background-color: var(--h7-color-main-border);
	margin-left: var(--h7-layout-main-xsmall-space);
	display: block;
	background-repeat: no-repeat;
	background-position: center;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}
.questions-icons .icon:hover {
	background-color: var(--h7-color-primary);
}
.questions-icons .icon:first-child {
	margin-left: 0;
}
.questions-icons .icon.icon-multiple-choice {
	background-image: url('../img/16x16-Multiple-Choice-Icon-Gray.svg');
}
.questions-icons .icon.icon-free-response {
	background-image: url('../img/16x16-Free-Response-Icon-Gray.svg');
}
.questions-icons .icon.icon-slider {
	background-image: url('../img/16x16-Slider-Icon-Gray.svg');
}

.questions-icons .icon.icon-multiple-choice:hover {
	background-image: url('../img/16x16-Multiple-Choice-Icon-White.svg');
}
.questions-icons .icon.icon-free-response:hover {
	background-image: url('../img/16x16-Free-Response-Icon-White.svg');
}
.questions-icons .icon.icon-slider:hover {
	background-image: url('../img/16x16-Slider-Icon-White.svg');
}

.chat-input-moderator.multiple-choice-active .icon-multiple-choice,
.chat-input-moderator.multiple-choice-active .icon-multiple-choice:hover,
.chat-input-moderator.free-response-active .icon-free-response,
.chat-input-moderator.free-response-active .icon-free-response:hover,
.chat-input-moderator.slider-active .icon-slider,
.chat-input-moderator.slider-active .icon-slider:hover {
	background-color: var(--h7-color-primary-30);
	border-color: var(--h7-color-primary);
}

.chat-input-moderator.multiple-choice-active .icon-multiple-choice,
.chat-input-moderator.multiple-choice-active .icon-multiple-choice:hover {
	background-image: url('../img/16x16-Multiple-Choice-Icon-Blue.svg');
}
.chat-input-moderator.free-response-active .icon-free-response,
.chat-input-moderator.free-response-active .icon-free-response:hover {
	background-image: url('../img/16x16-Free-Response-Icon-Blue.svg');
}
.chat-input-moderator.slider-active .icon-slider,
.chat-input-moderator.slider-active .icon-slider:hover {
	background-image: url('../img/16x16-Slider-Icon-Blue.svg');
}




/* 
=================================
CHAT STYLES
=================================
*/
.chat-container {
	position: relative;
}

/* Date Divider */
.chat-container .date {
	position: relative;
	margin: var(--h7-layout-main-space) 0;
	display: block;
}
.chat-container .date::after {
	height: 1px;
	background-color: var(--h7-color-main-border);
	content: "";
	top: 50%;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 9;
}
.chat-container .date .label {
	position: relative;
	z-index: 10;
	display: inline-block;
	padding: 2px var(--h7-layout-main-small-space);
	border-radius: 15px;
	background-color: var(--h7-color-page-background);
	border: 1px solid var(--h7-color-main-border);
	color: var(--h7-color-light-text);
	font-size: var(--h7-font-size-small);
}
.chat-container .date .label::before,
.chat-container .date .label::after {
	height: 4px;
	background-color: var(--h7-color-page-background);
	content: "";
	top: 50%;
	margin-top: -1px;
	position: absolute;
	z-index: 10;
	width: 15px;
}
.chat-container .date .label::before {
	left: -16px;
}
.chat-container .date .label::after {
	right: -16px;
}

/* Chat Item */
.chat-container .chat-item {
	background-color: var(--h7-color-main-layout);
	border-radius: var(--h7-layout-main-radius);
	padding: var(--h7-layout-main-medium-space);
	display: block;
	position: relative;
	margin-bottom: var(--h7-layout-main-xsmall-space);
	border: 1px solid var(--h7-color-main-layout);
	transition: border-color ease-in-out 0.3s, box-shadow ease-in-out 0.3s;
}
.chat-container .chat-item:hover {
	border-color: var(--h7-color-primary);
	box-shadow:0 16px 40px 0 rgba(0,0,0,0.15);
}
.chat-item p {
	margin: 0;
	word-break: break-word;
    overflow-wrap: break-word;
	padding-right: 62px;
}
.chat-item .user-type {
	font-weight: var(--h7-font-weight-medium);
	margin-bottom: 5px;
}
.chat-item .user-type .time {
	color: var(--h7-color-muted-text);
	font-size: var(--h7-font-size-small);
	text-transform: uppercase;
	display: inline-block;
	margin-left: var(--h7-layout-main-xsmall-space);
	font-weight: var(--h7-font-weight-regular);
}
.chat-item .chat-heading,
.chat-item .chat-body {
	position: relative;
	padding-left: 55px;
}
.chat-item .chat-heading::before {
	content: "";
	width: 40px;
	height: 40px;
	border-radius: 20px;
	background-color: var(--h7-color-panel);
	border: 1px solid var(--h7-color-main-border);
	display: block;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -20px;
}
.chat-item.moderator .chat-heading::before {
	background-image: url('../img/12-Avatar-Moderator-Icon.svg');
}
.chat-item.participant .chat-heading::before {
	background-image: url('../img/12-Avatar-Regular-Icon.svg');
}

/* Chat Item - Chat Body
	- For additional contect such as :
		~ Form (Free response question)
		~ Answers
		~ Slider
		~ Results
*/
.chat-item .chat-body {
	margin-top: var(--h7-layout-main-xsmall-space);
}
.chat-item .chat-body form {
	position: relative;
}
.chat-item .chat-body form .form-control {
	min-height: 80px; 
	border-radius: 20px;
	font-size: var(--h7-font-size-main);
	padding-left: var(--h7-layout-main-medium-space);
	padding-right: 80px;
	line-height: 16px;
}
.chat-item .chat-body form .btn-inline {
	position: absolute;
	right: 5px;
	top: 50%;
	margin-top: -15px;
	z-index: 9;
	background-color: transparent;
	border-color: transparent;
	color: var(--h7-color-primary)
}
.chat-item .button-group {
	position: absolute;
	right: 0;
	top: 50%;
	margin-top: -15px;
}
.chat-item .btn-sm {
	height: var(--h7-btn-sm-height);
	font-size: var(--h7-font-size-small);
	padding-left: var(--h7-layout-main-small-space);
	padding-right: var(--h7-layout-main-small-space);
	border-radius: calc(var(--h7-btn-sm-height) / 2);
	padding-top: 5px;
}

/* Collapse Expand Arrows - Chat Item */
.chat-item .btn-down,
.chat-item .btn-up {
	background-repeat: no-repeat;
	background-position: center;
	width: 30px;
}
.chat-item .btn-down:active,
.chat-item .btn-up:active {
	border-color: transparent;
	background-color: var(--h7-color-main-border);
}
.chat-item .btn-down {
	background-image: url('../img/20x20-Panel-Arrow-Down-Icon.svg');
}
.chat-item .btn-up {
	background-image: url('../img/20x20-Panel-Arrow-Up-Icon.svg');
}

/* Hidden Answer (Text) */
.chat-item .hidden-item {
	filter: blur(6px);
}

/* Answer Item */
.chat-item .answer-item {
	padding: 8px var(--h7-layout-main-medium-space);
	min-height: 40px;
	border: 1px solid var(--h7-color-main-border);
	border-radius: var(--h7-layout-main-radius);
	position: relative;
}
.chat-item .answer-item.auto-content {
	height: auto;
	padding: var(--h7-layout-main-small-space) var(--h7-layout-main-medium-space);
}
.chat-item .answer-item:has(.button-group) {
	padding-right: 90px;
}
.chat-item .answer-item.ai-response:has(.button-group) {
	padding-right: var(--h7-layout-main-medium-space);
}
.chat-item .answer-item.gray-bg {
	background-color: var(--h7-color-panel);
}
.answer-item.auto-content p:first-child {
	margin-bottom: 5px;
}
.answer-item span {
	position: relative;
	z-index: 3;
}
.answer-item span+strong {
	float: right;
}
.answer-item span.percentage {
	z-index: 1;
	left: 0;
	top: 0;
	bottom: 0;
	position: absolute;
	background-color: rgba(62, 100, 255, .15);
	border-radius: var(--h7-layout-main-radius);
}
.answer-item .button-group {
	right: 5px;
	top: 4px;
	margin-top: 0;
}
.answer-item.ai-response {
	border-color: var(--h7-color-primary);
	padding-top: var(--h7-layout-main-small-space);
}
.answer-item.auto-content .button-group {
	right: var(--h7-layout-main-medium-space);
	top: var(--h7-layout-main-medium-space);
	margin-top: 0;
}

/* AI response */
.ai-response .response-heading,
.ai-response .response-body {
	position: relative;
	padding-left: 55px;
}
.ai-response .response-heading {
	height: 40px;
	display: flex;
	align-items: center;
}
.ai-response .response-heading .button-group {
	top: 0;
	right: 0;
}
.ai-response .response-heading::before {
	content: "";
	width: 40px;
	height: 40px;
	border-radius: 20px;
	background-color: var(--h7-color-primary);
	display: block;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	left: 0;
	top: 50%;
	margin-top: -20px;
	background-image: url('../img/16x16-AI-Robot-Icon-White.svg');
}
.ai-response .response-body {
	font-family: "Roboto Mono", monospace;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
}
.ai-response .response-body ol {
	margin: var(--h7-layout-main-xsmall-space) 0;
}

.aiQuestions{
	border-color: var(--h7-color-primary);
	border-width: 0.8px;
	border-style: solid; 
	border-radius: 20px;
	padding: 10px;
	color: var(--h7-color-primary);
	transition: color 0.3s ease;
}

.aiQuestions-item:hover {
  color: #0934dd;
}

.chat-body .slider-question {
	margin: var(--h7-layout-main-xsmall-space) 0;
}
.chat-body .slider-actions {
	display: flex;
	justify-content: flex-start;
}
.chat-body .slider-actions .btn:first-child {
	margin-right: var(--h7-layout-main-xsmall-space);
}

/* Range Slider */
.range {
	display: block;
	width: 100%;
}
.range-input {
	-webkit-appearance: none;
	appearance: none;
	width: calc(100% - 50px);
	cursor: pointer;
	outline: none;
	border-radius: 5px;
	height: 10px;
	background: linear-gradient(to right, var(--h7-color-primary) var(--value), var(--h7-color-main-border) var(--value));
}
.range-input::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	height: 30px;
	width: 30px;
	background-color: var(--h7-color-main-layout);
	border-radius: 50%;
	border: none;
	transition: .2s ease-in-out;
	box-shadow: 0 0 0 4px rgba(55, 63, 94, .35);
}
.range-input::-moz-range-thumb {
	height: 30px;
	width: 30px;
	background-color: var(--h7-color-main-layout);
	border-radius: 50%;
	border: none;
	transition: .2s ease-in-out;
	box-shadow: 0 0 0 4px rgba(55, 63, 94, .35);
}
.range-input::-webkit-slider-thumb:hover {
	box-shadow: 0 0 0 10px rgba(62, 100, 255, .1)
}
.range-input:active::-webkit-slider-thumb {
	box-shadow: 0 0 0 6px rgba(62, 100, 255, .2)
}
.range-input:focus::-webkit-slider-thumb {
	box-shadow: 0 0 0 6px rgba(62, 100, 255, .2)
}

.range-input::-moz-range-thumb:hover {
	box-shadow: 0 0 0 6px rgba(62, 100, 255, .1)
}
.range-input:active::-moz-range-thumb {
	box-shadow: 0 0 0 6px rgba(62, 100, 255, .2)
}
.range-input:focus::-moz-range-thumb {
	box-shadow: 0 0 0 6px rgba(62, 100, 255, .2)
}

.range-value {
	font-size: 14px;
	width: 50px;
	text-align: center;
	float: right;
	font-weight: var(--h7-font-weight-semi-bold);
}
.range.range-static .range-input {
	cursor: default;
}
.range.range-static .range-input::-webkit-slider-thumb {
	visibility: hidden;
}
.range.range-static .range-input::-moz-range-thumb {
	visibility: hidden;
}

/* 
=================================
QUESTION PANEL STYLES
=================================
*/
.question-panel {
	z-index: 10;
	position: relative;
	background-color: var(--h7-color-panel);
	padding: 0;
	padding-bottom: 60px;
	margin: 0 var(--h7-layout-main-space);
	margin-bottom: -60px;
	box-shadow:0 -16px 40px 0 rgba(0,0,0,0.07);
	transition: margin-bottom 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.question-panel.minimize {
	box-shadow:0 -16px 40px 0 rgba(0,0,0,0);
}
.question-panel .card-header {
	padding-left: 50px;
	padding-right: 80px;
	position: relative;
	height: 60px;
	background-color: var(--h7-color-main-layout);
	border-bottom-color: var(--h7-color-main-border);
	border-radius: var(--h7-layout-main-radius) var(--h7-layout-main-radius) 0 0;
}
.card-header .icon-group {
	position: absolute;
	right: var(--h7-layout-main-small-space);
	top: 50%;
	margin-top: -10px;
	z-index: 5;
}
.card-header .icon-group .icon {
	margin: 0 5px;
	width: 20px;
	height: 20px;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: center;
	display: inline-block;
	content: "";
}
.icon.icon-minimize {
	background-image: url(../img/20x20-Panel-Arrow-Up-Icon.svg);
}
.icon.icon-close {
	background-image: url(../img/20x20-Panel-Close-Icon.svg);
}
.question-panel.minimize .icon.icon-minimize{
	background-image: url(../img/20x20-Panel-Arrow-Down-Icon.svg);
}
.question-panel .card-header strong {
	margin-right: var(--h7-layout-main-small-space);
}
.question-panel .card-header small {
	color: var(--h7-color-muted-text);
}
.question-panel .card-header::before {
	width: 20px;
	height: 20px;
	content: "";
	left: var(--h7-layout-main-medium-space);
	top: 50%;
	margin-top: -10px;
	position: absolute;
	background-repeat: no-repeat;
	background-position: center;
}
.multiple-choice-panel .card-header::before {
	background-image: url(../img/16x16-Multiple-Choice-Icon-Blue.svg);
}
.free-response-panel .card-header::before {
	background-image: url(../img/16x16-Free-Response-Icon-Blue.svg);
}
.slider-panel .card-header::before {
	background-image: url(../img/16x16-Slider-Icon-Blue.svg);
}
.question-panel .card-body {
	padding: var(--h7-layout-main-medium-space);
}
.question-panel .list-group {
	padding: 0;
	margin: 0;
}
.question-panel .list-group-item {
	padding: 0;
	margin: 0;
	margin-bottom: var(--h7-layout-main-xsmall-space);
	color: var(--h7-color-main-text);
	background-color: var(--h7-color-panel);
	border-width: 0;
}
.question-panel .list-group-item:last-child {
	margin-bottom: 0;
}
.question-panel .btn-check+.btn {
	padding-top: 13px;
	padding-left: var(--h7-layout-main-space);
	height: var(--h7-input-height);
	border-radius: calc(var(--h7-input-height) / 2);
	min-height: var(--h7-input-height);
}
.question-panel .btn-check+.btn-outline-secondary {
	border-color: transparent;
}




/* 
=================================
HIDE EVERYTHING IN DESKTOP
=================================
*/
.chat-input .main-input .btn::before,
.chat-input .main-input .plus-icon-questions,
header .btn-menu,
.header-logo {
	display: none;
}


/* 
=================================
RESPONSIVE BREAKPOINTS
=================================
*/
@media only screen and (min-width: 1025px) {
	.dropdown.dropdown-actions {
		display: block;
		position: static;
	}
	.dropdown-actions .dropdown-menu {
		display: block;
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -15px;
		display: flex;
		height: 30px;
		padding: 0;
		z-index: 1;
		border-width: 0;
		min-width: auto;
		background-color: transparent;
	}
	.chat-heading:has(.button-group) .dropdown-actions .dropdown-menu {
		right: 40px;
	}
	.dropdown-actions .dropdown-menu li > .dropdown-item {
		display: inline-block;
		width: auto;
		height: var(--h7-btn-sm-height);
		font-size: var(--h7-font-size-small);
		padding-left: var(--h7-layout-main-small-space);
		padding-right: var(--h7-layout-main-small-space);
		border-radius: calc(var(--h7-btn-sm-height) / 2);
		background-color: var(--h7-color-main-border);
		border-color: var(--h7-color-main-border);
		color: var(--h7-color-main-text);
		font-weight: var(--h7-font-weight-semi-bold);
		margin-right: calc(var(--h7-layout-main-xsmall-space) / 2);
		padding-top: 6px;
		user-select: none;
	}
	.dropdown-actions .dropdown-menu li:last-child > .dropdown-item {
		margin-right: 0;
		user-select: none;
	}
	.dropdown-actions .dropdown-menu li > .dropdown-item:hover,
	.dropdown-actions .dropdown-menu li > .dropdown-item.active:hover {
		background-color: var(--h7-color-secondary-hover);
		border-color: var(--h7-color-secondary-hover);
		user-select: none;
	}
}

@media only screen and (max-width: 1024px) {

	/* Bootstrap re-styling */
	.tooltip {
		display: none;
	}

	/* Layout */
	.main {
		margin-left: 0;
		width: 100%;
	}

	/* Header */
	header {
		display: block;
		left: 0;
		position: fixed;
		display: block;
		justify-content: none;
	}
	header .btn-menu {
		display: block;
		position: absolute;
		left: var(--h7-layout-main-space);
		top: 50%;
		margin-top: -20px;
	}
	header .button-group {
		position: absolute;
		right: var(--h7-layout-main-space);
		top: 50%;
		margin-top: -20px;
	}
	header .btn-menu,
	header .button-group .btn-primary,
	header .button-group .btn-secondary { 
		width: 40px;
		padding: var(--h7-layout-main-xsmall-space);
		border-radius: 10px;
		font-size: 0;
	}
	header .btn-menu::before,
	header .button-group .btn-primary::before,
	header .button-group .btn-secondary::before {
		left: 50%;
		margin-left: -10px;
	}
	.header-logo {
		display: flex;
		margin-bottom: 0;
	}
	.header-logo img {
		width: 40px;
	}
	
	/* Sidebar */
	.sidebar-inner {
		display: block;
		position: relative;
		padding-top: var(--h7-layout-main-space);
		padding-bottom: var(--h7-layout-main-xsmall-space);
	}
	.moderator-wrapper .sidebar-inner {
		padding-bottom: 90px;
		padding-top: var(--h7-layout-main-space);
	}
	.sidebar-logo {
		display: none;
	}
	.sidebar-inner > .d-grid {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		padding: var(--h7-layout-main-medium-space);
		width: calc(var(--h7-layout-sidebar-size) - 1px);
		background-color: var(--h7-color-main-layout);
		box-shadow: 0 -2px 4px 0 rgba(0,0,0,0.07);
	}
	.sidebar.fixed,
	.sidebar-inner > .d-grid {
		left: calc(var(--h7-layout-sidebar-size) * -1);
		transition: left ease-in-out 0.3s;
	}
	.sidebar.fixed.slide-right,
	.sidebar.fixed.slide-right .sidebar-inner .d-grid {
		left: 0;
	}

	/* Dropdown */
	.dropdown-actions {
		position: absolute;
		right: var(--h7-layout-main-medium-space);
		width: 40px;
		height: 50px;
		content: "";
		right: -10px;
		top: 50%;
		margin-top: -25px;
		display: block;
	}
	.dropdown-actions .more-icon {
		width: 40px;
		height: 50px;
		content: "";
		display: block;
		cursor: pointer;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url(../img/17-More-Icon-Gray.svg);
	}

	/* Overlay */
	@keyframes fadeInOut { 
		0% { 
			opacity: 0; 
			display: none; 
		} 
	  
		50% { 
			opacity: 0.5; 
			display: block; 
		} 
	  
		100% { 
			opacity: 1; 
			display: block; 
		} 
	}
	.overlay {
		opacity: 0;
		display: none;
		animation: fadeInOut 0.6s ease-in-out; 
	}
	#overlay {
		display: none;
	}
	#overlay.block-content {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 29;
	  }

	/* Chat */
	.chat-item .btn-down,
	.chat-item .btn-up {
		display: none;
	}

	/* Chat Input */
	.chat-input.fixed {
		left: 0;
	}
	.chat-input .main-input .form-control{
		padding-left: var(--h7-layout-main-space);
		padding-right: 60px;
	}
	.chat-input-moderator .main-input .form-control {
		padding-left: 60px;
	}
	
	.chat-input .main-input .btn {
		width: 40px;
		height: 40px;
		font-size: 0;
		padding: var(--h7-layout-main-xsmall-space);
	}
	.chat-input .main-input .btn::before {
		display: block;
		width: 20px;
		height: 20px;
		content: "";
		position: absolute;
		left: 50%;
		margin-left: -10px;
		top: 50%;
		margin-top: -9px;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url(../img/16-Send-Icon-White.svg)
	}
	.chat-input .main-input .plus-icon-questions {
		position: absolute;
		right: var(--h7-layout-main-medium-space);
		width: 30px;
		height: 30px;
		content: "";
		left: var(--h7-layout-main-medium-space);
		top: 50%;
		margin-top: -15px;
		z-index: 4;
		display: block;
		cursor: pointer;
		background-repeat: no-repeat;
		background-position: center;
		background-image: url(../img/16-Plus-Icon-Blue.svg);
	}
	.chat-input .main-input .plus-icon-questions.close {
		background-image: url(../img/16-Close-Icon-Red.svg);
	}
	.chat-input .main-input .questions-icons {
		position: relative;
		right: 0;
		top: 0;
		margin-top: var(--h7-layout-main-xsmall-space);
		display: none;
	}
	.chat-input .main-input .questions-icons.show-up {
		display: block;
	}

	/* Active question icon */
	.chat-input-moderator.multiple-choice-active .question-input::before,
	.chat-input-moderator.free-response-active .question-input::before,
	.chat-input-moderator.slider-active .question-input::before {
		position: absolute;
		left: 0;
		margin-top: -20px;
		top: 50%;
		width: 40px;
		height: 40px;
		border: 1px solid var(--h7-color-primary);
		content: "";
		border-radius: 20px;
		background-color: var(--h7-color-primary-30);
		margin-left: var(--h7-layout-main-xsmall-space);
		display: block;
		background-repeat: no-repeat;
		background-position: center;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		pointer-events: all;
	}
	.chat-input-moderator.multiple-choice-active .question-input::before {
		background-image: url('../img/16x16-Multiple-Choice-Icon-Blue.svg');
	}
	.chat-input-moderator.free-response-active .question-input::before{
		background-image: url('../img/16x16-Free-Response-Icon-Blue.svg');
	}
	.chat-input-moderator.slider-active .question-input::before {
		background-image: url('../img/16x16-Slider-Icon-Blue.svg');
	}

	.chat-input-moderator.multiple-choice-active .plus-icon-questions,
	.chat-input-moderator.free-response-active .plus-icon-questions,
	.chat-input-moderator.slider-active .plus-icon-questions {
		display: none;
	}

	/* Question Icons */
	.questions-icons .icon {
		width: 100%;
		height: 50px;
		border-color: var(--h7-color-main-border);
		border-radius: 25px;
		background-color: var(--h7-color-main-layout);
		margin-left: 0;
		margin-bottom: var(--h7-layout-main-xsmall-space);
		display: block;
		background-repeat: no-repeat;
		background-position: center;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		position: relative;
		padding: 13px;
		padding-left: 60px;
		text-decoration: none;
		color: var(--h7-color-main-text);
	}
	.questions-icons .icon:hover {
		color: var(--h7-color-main-layout);
	}
	.chat-input-moderator.multiple-choice-active .icon-multiple-choice,
	.chat-input-moderator.multiple-choice-active .icon-multiple-choice:hover,
	.chat-input-moderator.free-response-active .icon-free-response,
	.chat-input-moderator.free-response-active .icon-free-response:hover,
	.chat-input-moderator.slider-active .icon-slider,
	.chat-input-moderator.slider-active .icon-slider:hover {
		color: var(--h7-color-primary);
	}
	.questions-icons .icon::after {
		position: absolute;
		left: -5px;
		top: 4px;
		width: 40px;
		height: 40px;
		border: 1px solid var(--h7-color-primary);
		content: "";
		border-radius: 20px;
		background-color: var(--h7-color-primary-30);
		margin-left: var(--h7-layout-main-xsmall-space);
		display: block;
		background-repeat: no-repeat;
		background-position: center;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
	}
	.questions-icons .icon.icon-multiple-choice::before {
		content: "Multiple Choice"
	}
	.questions-icons .icon.icon-free-response::before {
		content: "Free Response"
	}
	.questions-icons .icon.icon-slider::before {
		content: "Slider"
	}
	.questions-icons .icon.icon-multiple-choice:hover,
	.questions-icons .icon.icon-multiple-choice,
	.questions-icons .icon.icon-free-response,
	.questions-icons .icon.icon-free-response:hover,
	.questions-icons .icon.icon-slider,
	.questions-icons .icon.icon-slider:hover {
		background-image: none;
	}
	
	.questions-icons .icon.icon-multiple-choice::after {
		background-image: url('../img/16x16-Multiple-Choice-Icon-Blue.svg');
	}
	.chat-input-moderator.multiple-choice-active .icon-multiple-choice::after {
		background-image: url('../img/16x16-Multiple-Choice-Icon-White.svg');
		background-color: var(--h7-color-primary);
	}
	
	.questions-icons .icon.icon-free-response::after {
		background-image: url('../img/16x16-Free-Response-Icon-Blue.svg');
	}
	.chat-input-moderator.free-response-active .icon-free-response::after {
		background-image: url('../img/16x16-Free-Response-Icon-White.svg');
		background-color: var(--h7-color-primary);
	}
	
	.questions-icons .icon.icon-slider::after {
		background-image: url('../img/16x16-Slider-Icon-Blue.svg');
	}
	.chat-input-moderator.slider-active .icon-slider::after {
		background-image: url('../img/16x16-Slider-Icon-White.svg');
		background-color: var(--h7-color-primary);
	}
}

	/*Mulitple Choice Message Item */
	.mc-chat-heading {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: nowrap;
	}
	
	.mc-moderator-info {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
	
	.mc-user-type {
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
	.mc-time {
		margin-left: 10px;
		white-space: nowrap;
	}
	
	.mc-responses {
		text-align: right;
		font-weight: bold;
		white-space: nowrap;
		margin-left: auto;
		min-width: 100px;
		align-self: flex-start; /* Ensure it stays aligned at the top */
	}
	
	.mc-message-container {
		margin-top: 5px; /* Add space between user type and question */
		word-break: break-word;
		flex: 1;
	}
	  
	  /* Responsive Breakpoints */
	  @media only screen and (max-width: 1024px) {
		.mc-chat-heading {
			flex-wrap: wrap;
			justify-content: space-between;
		}
	
		.mc-user-type, .mc-time, .mc-responses {
			font-size: 14px;
			white-space: nowrap;
		}
	
		.mc-moderator-info {
			flex: 1;
			text-align: left;
		}
	
		.mc-responses {
			text-align: right;
			margin-top: 5px;
			min-width: 80px;
			margin-left: 0;
			align-self: flex-start;
		}
	  }
	  
	  @media only screen and (max-width: 640px) {
		.mc-chat-heading {
			flex-direction: row;
			align-items: flex-start;
		}
	
		.mc-user-type {
			margin-bottom: 5px;
		}
	
		.mc-responses {
			text-align: left;
			width: 100%;
			margin-top: 5px;
			margin-left: 0;
		}
	  }
	  


@media only screen and (max-width: 640px) {
	
	/* Modal (Bootstrap re-styling) */
	.modal-body {
		padding-right: var(--h7-layout-main-space);
		padding-left: var(--h7-layout-main-space);
	}
	
	/* Landing Pages */
	body.landing-body {
		background-color: var(--h7-color-main-layout);
	}
	.landing-container {
		width: 100%;
		margin-bottom: 0;
	}
	.landing-container .card.panel {
		border-color: transparent;
		background-color: transparent;
		padding: var(--h7-layout-main-space);
	}
	.landing-container .landing-logo {
		height: 175px;
		margin-top: var(--h7-layout-main-space);
	}

	/* Header */
	header .btn-menu {
		left: var(--h7-layout-main-small-space);
	}
	header .button-group {
		right: var(--h7-layout-main-small-space);
	}
	.moderator-wrapper .header-logo {
		position: absolute;
		left: 55px;
	}
	/* Main content */
	/* Chats */
	.chat-container .date {
		margin: var(--h7-layout-main-medium-space) 0;
	}
	.chat-container .chat-item {
		padding: var(--h7-layout-main-xsmall-space);
	}
	.chat-item .chat-heading {
		padding-right: var(--h7-layout-main-space);
	}
	.chat-item .chat-body {
		padding-left: 0;
	}
	.chat-input-body {
		padding: var(--h7-layout-main-medium-space);
	}
	.chat-body .slider-actions {
		gap: var(--h7-layout-main-xsmall-space);
	}
	.chat-body .slider-actions .btn:first-child {
		margin-right: 0;
	}
	.chat-body .slider-actions .btn {
		width: 100%;
	}
	.ai-response .response-body {
		padding-left: 0;
		margin-top: var(--h7-layout-main-xsmall-space);
	}

	/* Question Panel */
	.question-panel {
		margin-right: var(--h7-layout-main-medium-space);
		margin-left: var(--h7-layout-main-medium-space);
	}
	.question-panel .card-header  {
		padding-top: 0;
		padding-bottom: 0;
		flex-flow: row wrap;
	}
	.question-panel .card-header small {
		display: block;
		width: 100%;
		margin: 0;
	}
	.question-panel .card-header strong {
		margin-right: 0;
		width: 100%;
		margin-bottom: -20px;
	}
}

@media only screen and (max-width: 575.98px) {
	/* Stying for Bootstrap Modal */
	.modal-body {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.modal-fullscreen-sm-down .modal-content {
	  min-height: 100%;
	  height: auto;
	}
}

/* 
=================================
SHOWCASE (DEMO ONLY)
=================================
*/
.component-name {
	font-size: var(--h7-font-size-medium);
}
.chat-input.showcase .chat-input-body {
	border: 1px solid var(--h7-color-main-border);
	border-radius: var(--h7-layout-main-radius);
}
.full-height {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	padding: 0;
}


/* Markdown */

.markdown p {
	margin: 0.5em 0;
  }
.markdown h1, .markdown h2, .markdown h3, .markdown h4, .markdown h5, .markdown h6 {
	margin: 1em 0 0.5em;
  }
.markdown ul {
	padding-left: 1.5em;
  }
.markdown pre {
	background: #f5f5f5;
	padding: 1em;
	border-radius: 5px;
  }
.markdown code {
	background: #f5f5f5;
	padding: 0.2em 0.4em;
	border-radius: 3px;
  }

  .custom-alert,
  .dropdown-alert {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	background-color: #FFBA08; /* Yellow background similar to your app's color scheme */
	color: #000; /* Black text color */
	padding: 5px 10px; /* Reduced padding */
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	font-size: 12px; /* Smaller font size */
	z-index: 1000;
	white-space: nowrap;
	user-select: none;
  }
  
  .custom-alert::before,
  .dropdown-alert::before {
	content: '';
	position: absolute;
	top: -8px; /* Adjusted position for smaller alert */
	left: 50%;
	transform: translateX(-50%);
	border-width: 5px; /* Smaller arrow */
	border-style: solid;
	border-color: transparent transparent #FFBA08 transparent;
	user-select: none;
  }
  
  /* Ensure dropdown menu and alert don't overflow the screen */
  @media (max-width: 600px) {
  
	.dropdown-alert {
	  max-width: 90vw;
	  word-wrap: break-word;
	  left: 5%;
	  transform: none;
	  user-select: none;
	}
  }

  .dropdown-actions .dropdown-alert {
	position: absolute;
	top: 50px; /* Adjust as needed to position below the dots */
	left: 50%;
	transform: translateX(-50%);
	background-color: #FFBA08; /* Yellow background similar to your app's color scheme */
	color: #000; /* Black text color */
	padding: 5px 10px; /* Reduced padding */
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	font-size: 12px; /* Smaller font size */
	z-index: 1000;
	white-space: nowrap;
	user-select: none;
  }
  
  .logout-button-container {
	position: absolute;
	top: 10px;
	right: 10px;
  }

  .question-input .invalid-feedback {
	display: block;
	color: red;
	position: absolute;
	bottom: -20px;
	left: 0;
	width: 100%;
  }
  
  